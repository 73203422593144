import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faCog } from '@fortawesome/free-solid-svg-icons';

function MaintenancePage({ isAdmin, message }) {
  return (
    <div className="w-full h-full bg-[#F2F5FF] grid place-items-center">
      <div className="text-center">
        <FontAwesomeIcon icon={faTools} className="text-6xl text-primary-red mb-4" />
        <h1 className="text-2xl font-bold mb-2">Under Maintenance</h1>
        <p className="text-gray-600 mb-4">{message || 'This application is currently offline for maintenance.'}</p>
        {isAdmin && (
          <Link
            to="/management/configuration#maintenance"
            className="inline-flex items-center text-primary-red hover:text-primary-red-dark"
          >
            <FontAwesomeIcon icon={faCog} className="mr-2" />
            Go to Configuration
          </Link>
        )}
      </div>
    </div>
  );
}

MaintenancePage.propTypes = {
  isAdmin: PropTypes.bool,
  message: PropTypes.string
};

export default MaintenancePage;
