import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useRecoilState } from 'recoil';

// State
import { isLoggedIn as loginState, profileState, configurationState, maintenanceState } from 'state';
// Components
import Seo from 'components/Seo';
import LoadingPage from 'ui/LoadingPage';
import MaintenancePage from 'ui/MaintenancePage';
// Auth Function
import { checkLoginStatus } from 'func/auth';

function IndexPage() {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginState);
  const [profile, setProfile] = useRecoilState(profileState);
  const [, setConfiguration] = useRecoilState(configurationState);
  const [maintenance, setMaintenance] = useRecoilState(maintenanceState);

  const initialize = async () => {
    const result = await checkLoginStatus();
    if (!result) return navigate('/login');
    const { profile, isLoggedIn, configuration } = result;
    setProfile(profile);
    setConfiguration(configuration);
    setMaintenance({
      online: profile.options.maintenance.online,
      message: profile.options.maintenance.message || 'This application is currently offline for maintenance.',
      isAdmin: profile.role.value === 'Administrator'
    });
    return setTimeout(() => setIsLoggedIn(isLoggedIn), 1000);
  };

  useEffect(() => {
    // Check the Valid Token
    if (profile?.role?.value === 'Guest') {
      navigate('/guest/booking/');
      return;
    }

    // Route to default if available in the profile
    if (profile?.options?.defaults?.module) {
      navigate(profile?.options?.defaults?.module);
      return;
    }
    if (isLoggedIn) {
      navigate('/booking/create/');
      return;
    }
    initialize();
  }, [isLoggedIn]);

  return (
    <>
      <LoadingPage hide={isLoggedIn} />
      {!maintenance.online && (
        <MaintenancePage isAdmin={profile?.role?.value === 'Administrator'} message={maintenance.message} />
      )}
      {profile && maintenance.online && <Seo title="Veezu - eBooker" />}
    </>
  );
}

export default IndexPage;
